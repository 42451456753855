import { useUserStore } from '~/store/user/user.store';

/* Хранилище контроллера ежедневных бесплатных кейсов */
export const useGenerateUserLevelStore = defineStore('global/generateUserLevel', () => {
  const { $api } = useNuxtApp();

  const userStore = useUserStore();

  const isReadyToGenerate = computed(() => userStore.currentUser?.userLevel === '-1');

  const generateLevel = () => {
    return GlobalUtils.Api.handleRequest(async () => {
      const result = await $api.user.generateLevel();
      if (!result) return false;
      // await userStore.getMe();
      return true;
    });
  };

  return {
    generateLevel,
    isReadyToGenerate,
  };
});
